<template>

<div class="event">

	<div class="event-image" v-bgimage="event.image"></div>

	<div class="event-info">

		<div class="event-info-name">{{ event.name }}</div>
		<div class="event-info-date">{{ event.date | formatDate('h:mma, Do MMMM YYYY') }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['event']

}

</script>

<style scoped>

.event-image {
	height: 200px;
	background-color: #fff;
	flex-shrink: 0;
	background-size: cover;
	background-position: 50% 50%;
}

.event-info {
	padding: 10px;
	flex-shrink: 0;
	color: #fff;
	background-color: #4082d3;
}

.event-info-name {
	font-weight: 400;
	font-size: 24px;
	margin-bottom: 2px;
	text-align: center;
}

.event-info-date {
	font-weight: 300;
	font-size: 14px;
	margin-top: 4px;
	text-align: center;
}

</style>
